<template>
  <v-container fluid dark>
    <v-row>
      <v-col xs12>
        <v-card>
          <h1>TAKT.ing Authentication</h1>
          <h2>Logging you in save and sound...</h2>
          <h3>Loading User Data...</h3>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'AWSAuth',
  mounted(){
    // console.log(this.$route);
    localStorage.setItem('TAKT_redirect_route', this.$route.query.redirect);
    window.location.replace(process.env.VUE_APP_LOGIN_URL);
  },
  methods: {
    ...mapActions('Auth', [
      'requestAccessToken'
    ])
  }
};
</script>
